<template>
  <df-modal
    persistent
    :action-name="$t('aplicar')"
    :title="$t('SowingsPlanning.custom_period_modal.custom_period')"
    @action-click="filterPeriod"
    @close="closeModal"
  >
    <template #card-content>
      <v-form ref="customPeriodForm">
        <div class="df-flex-l df-flex-col-t df-flex-col-m">
          <div class="df-col-6 df-col-12-t df-col-12-m">
            <df-date-picker
              v-model="startDate"
              data-id="start-date-picker"
              :label="$t('SowingsPlanning.custom_period_modal.start')"
              :placeholder="
                $t('SowingsPlanning.custom_period_modal.insert_date')
              "
              :rules="[required]"
            />
          </div>
          <div class="df-col-6 df-col-12-t df-col-12-m">
            <df-date-picker
              v-model="endDate"
              data-id="end-date-picker"
              :label="$t('SowingsPlanning.custom_period_modal.end')"
              :min="startDate"
              :placeholder="
                $t('SowingsPlanning.custom_period_modal.insert_date')
              "
              :rules="[required, dateShouldBeAfter(endDate, startDate)]"
            />
          </div>
        </div>
      </v-form>
    </template>
  </df-modal>
</template>

<script>
import DfDatePicker from '@/lib/components/DatePicker/DfDatePicker.vue'
import DfModal from '@/lib/Modal/DfModal.vue'
import { dateShouldBeAfter, required } from '@/utils/formRules'

export default {
  name: 'CustomPeriodModal',

  components: {
    DfDatePicker,
    DfModal,
  },

  data() {
    return {
      dateShouldBeAfter,
      endDate: null,
      required,
      startDate: null,
    }
  },

  methods: {
    closeModal() {
      this.$emit('close')
    },
    filterPeriod() {
      if (!this.$refs.customPeriodForm.validate()) return
      const period = { startDate: this.startDate, endDate: this.endDate }
      this.closeModal()
      this.$emit('apply-filter', period)
    },
  },
}
</script>
